import React, { Component } from 'react';

import OrdersComponent from '../../components/account/Orders';

class Orders extends Component {
  render() {
    const { site } = this.props;

    return <OrdersComponent site={site} />;
  }
}

export default Orders;
