import Helmet from 'react-helmet';
import React, { Component } from 'react';

import AccountFrame from './AccountFrame';
import Login from './helpers/Login';
import Order from './helpers/Order';
import withAccount from '../../containers/plugins/withAccount';
import { getCmsValue } from '../../utils/utils';

class Orders extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { accessToken, getAccount, getOrders } = this.props;

    accessToken && getAccount() && getOrders();
  }

  componentDidUpdate(prevProps) {
    const { accessToken, getAccount, getOrders } = this.props;

    if (accessToken && accessToken !== prevProps.accessToken)
      getAccount() && getOrders();
  }

  render() {
    const { accessToken, account, orders, site } = this.props;

    return (
      <AccountFrame
        title={getCmsValue(site.strings.accountPage__orders__title, site)}
        {...this.props}
      >
        {accessToken && account ? (
          orders && (
            <div className="orders">
              <ul className="account-section account-section__orders">
                {orders?.length > 0 &&
                  orders.map((order) => (
                    <Order key={order.id} order={order} site={site} />
                  ))}
              </ul>
            </div>
          )
        ) : (
          <Login site={site} />
        )}
      </AccountFrame>
    );
  }
}

export default withAccount(Orders);
